:root{
  --animation-curve: cubic-bezier(1,0,0,1); 
}

@font-face {
  font-family: Helvetica;
  src: url(./assets/Helvetica.ttf);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-color: #F9F9F9;
}

p, h1, h2, h3, h4, a, span{
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  color: #1D1D1B;
}

.container{
  width: 1368px;
  margin: 0 auto;
}

.logo{
  padding: 48px 64px;
  background-color: #F9F9F9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: .6s ease-in-out;
}

.logo .menu-hamburguer{
  display: none;
}

.mobile-header{
  display: none;
}

header{
  width: 1368px;
  margin: 0 auto;
  position: relative;
}

header ul{
  list-style: none;
  position: fixed;
  right: 64px;
  top: 300px;
  z-index: 1000;
}

header ul a{
  display: block;
  font-size: 24px;
  margin-bottom: 16px;
  text-decoration: none;
  text-align: end;
}

header ul a:hover{
  text-decoration: underline;
}

.change-language {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.change-language a {
  width: 79px;
  height: 48px;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  line-height: 24px;
  margin-left: 16px;
  background-color: #F9F9F9;
  border: 2px solid black;
  border-radius: 39px;
  padding: 12px 0;
}

.change-language a:hover {
  background-color: #F15A29;
  border: 2px solid transparent;
  color: black;
  transition: .3s ease-in-out;
}

.change-language a.active {
  background-color: #1D1D1B;
  color: white;
}

.hero{
  padding: 236px 0 120px;
}

.hero .container{
  position: relative;
}

.hero .blur{
  position: absolute;
  z-index: 1010;
  pointer-events: none;
  width: 352px;
  height: 352px;
  right: 6%;
  top: -230px;
  background-image: url('/src/assets/blur-desktop.svg');
}

.hero h1{
  width: 755px;
  text-transform: uppercase;
  font-size: 64px;
  margin-bottom: 48px;
  line-height: 88px;
}

.hero span{
  font-size: 64px;
  text-transform: uppercase;
}

.hero .workTitle{
  position: relative;
}

.hero .workTitle span{
  font-family: adobe-caslon-pro, serif;
  font-weight: 400;
  font-style: italic;
}

.hero .workLottie{
  position: absolute;
  top: -28px;
  right: -45px;
  content: '';
  pointer-events: none;
  z-index: -1;
}

.hero .arrowLottie{
  position: absolute;
  right: 20%;
  bottom: 50%;
  content: '';
}

.hero h2{
  width: 755px;
  font-size: 40px;
  line-height: 56px;
  margin-top: 118px;
}

.about {
  background-color: #1D1D1B;
  padding: 120px 0;
}

.about .container{
  display: flex;
  justify-content: space-between;
}

.about .about__description{
  width: 644px;
}

.about .about__description h2{
  color: white;
  font-size: 40px;
  line-height: 56px;
}

.about .about__description h2:first-of-type{
  margin-bottom: 48px;
}

.experience {
  padding: 120px 0;
}

.experience h2{
  max-width: 755px;
  font-size: 40px;
  line-height: 56px;
}

.experience h2:first-of-type{
  margin-bottom: 48px;
}

.about-my-work {
  padding: 0 0 120px;
}

.about-my-work h2 {
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 64px;
}

.work {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  cursor: pointer;
  text-decoration: none;
}

.work:hover{
  .arrow{
    width: 40px;
    margin-right: 24px;
  }
  .arrow::before, .arrow::after{
    width: 18px;
  }
}

.works a:first-of-type{
  border-bottom: none;
}

.work-title {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.work .arrow {
  height: 3px;
  width: 0px;
  justify-content: center;
  align-items: center;
  background-color: black;
  margin-right: 0px;
  position: relative;
  transition: .3s ease-in-out;
}

.work .arrow::before{
  content: '';
  position: absolute;
  width: 0px;
  height: 3px;
  background-color: black;
  transform: rotateZ(45deg);
  top: -5.5px;
  right: -4px;
  transition: .3s ease-in-out;
}

.work .arrow::after{
  content: '';
  position: absolute;
  width: 0px;
  height: 3px;
  background-color: black;
  transform: rotateZ(-45deg);
  bottom: -5.5px;
  right: -4px;
  transition: .3s ease-in-out;
}

.work h4 {
  font-size: 40px;
  font-weight: 400;
}

.work span {
  font-size: 24px;
  margin-right: 40px;
  padding: 24px 40px;
  border: 2px solid black;
  border-radius: 48px;
  &:last-of-type {
    margin-right: 0;
  }
}

.projects{
  width: 100%;
  margin-bottom: 80px;
}

.projects-container{
  width: 100%;
  max-width: 100vw;
}

.slide-item{
  position: relative;
}

.comming-soon{
  content: '';
  top: 0;
  left: 0;
  width:100%;
  position: absolute;
  height: 100%;
  background-color: rgba(0,0,0, 0.75);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: .3s ease-in-out;
}

.comming-soon .comming-soon-text, .comming-soon .comming-soon-n{
  color: white;
}

.comming-soon .comming-soon-text{
  font-weight: 400;
  font-size: 80px;
}

.comming-soon .comming-soon-n{
  font-family: adobe-caslon-pro, serif;
  font-weight: 600;
  font-style:italic;
  margin-right: 2px;
  font-size: 86px;
}

.slide-item:hover .comming-soon, .slide-item:focus .comming-soon{
  opacity: 1;
}

.projects .slick-dots{
  bottom: 0;
  left: 0;
  background: rgba(29, 29, 27, 0.8);
  width: auto;
  padding: 32px 64px;
}

.slick-dots li{
  width: auto;
  margin: 0;
}

.projects .slick-dots li a{
  color: #F9F9F9;
  font-size: 20px;
  display: block;
  margin: 0;
  padding: 0 8px;
}

.projects .slick-dots li:first-of-type a, .projects .slick-dots li:last-of-type a{
  margin: 0;
}

.projects .slick-dots .slick-active a{
  font-family: adobe-caslon-pro, serif;
  font-weight: 400;
  font-style: italic;
  padding: 5px 14px;
  border: 1px solid white;
  width: 40px;
  height: 40px;
  margin-right: 0;
  border-radius: 50%;
  position: relative;
}

.projects .slick-dots .slick-active:nth-child(1) a{
  margin-right: 96px;
}
.projects .slick-dots .slick-active:nth-child(2) a{
  margin-right: 8px;
}

.projects .slick-dots .slick-active:nth-child(2) a, .projects .slick-dots .slick-active:nth-child(3) a{
  margin-left: 96px;
}

.slick-active a::before{
  position: absolute;
  display: block;
  width: 80px;
  height: 1px;
  background: white;
  content: '';
}

.slick-active:nth-child(1) a::before {
  left: calc(100% + 10px);
  top: 50%;
}

.slick-active:nth-child(2) a::before {
  right: calc(100% + 10px);
  top: 50%;
}

.slick-active:nth-child(3) a::before {
  right: calc(100% + 10px);
  top: 50%;
}


.projects img{
  width: 100%;
  position: relative;
}

.hobbies{
  padding: 40px 0 148px;
}

.hobbies .container{
  display: flex;
}

.hobbies h3{
  font-size: 43px;
  max-width: 755px;
  line-height: 56px;
  margin-right: 136px;
}

.hobbies h3 span{
  font-family: adobe-caslon-pro, serif;
  font-weight: 400;
  font-style: italic;
}

.hobbies img{
  position: absolute;
}

footer{
  width: 100%;
  margin: 0 auto;
  background-color: #F15A29;
  padding: 80px 0 40px;
}

footer h3, p{
  font-size: 40px;
  line-height: 56px;
}

footer h3{
  margin: 48px 0;
}

footer h3:first-of-type{
  margin: 0 0 48px;
}

footer h3:last-of-type{
  margin: 48px 0 0;
}

footer hr{
  border:none;
  height: 3px;
  background-color: #1D1D1B;
  margin: 64px 0 40px;
}

@media screen and (max-width: 1440px) {
  .container{
    width: calc(100% - 128px);
  }
}
@media screen and (max-width: 1140px) {
  .hero .arrowLottie{
    right: 10%;
    bottom: 45%;
  }
}

@media screen and (min-width: 769px) {
  .hideDesktop{
    display: none;
  }

  .change-language {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    z-index: 1000;
    right: 64px;
    top: 44px;
  }
  
}

@media screen and (max-width: 768px) {
  .container{
      width: calc(100% - 40px);
      margin: 0 20px;
  }

  .hideMobile{
    display: none;
  }

  header{
      display: none;
  }

  .mobile-header{
      width: 100%;
      height: calc(100vh - 64px);
      position: fixed;
      z-index: 8;
      display: block;
      top: 64px;
      left: 0;
  }

  .mobile-header .logo{
      background-color: #F15A29;
  }

  .change-language {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 44px;
  }  

  .mobile-header ul{
      margin-top: 44px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      flex-direction: column;
  }

  .mobile-header ul a{
      display: block;
      font-size: 40px;
      line-height: 56px;
      text-decoration: none;
      margin-bottom: 16px;
  }

  .mobile-header .contact{
      margin-top: 32px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
  }

  .mobile-header .contact a{
      font-size: 24px;
      margin-bottom: 24px;
      line-height: 40px;
  }

  .mobile-header .coming-soon{
      background-color: #1D1D1B;
      padding: 8px 0;
      color: white;
      text-transform: uppercase;
      font-family: 'Helvetica', sans-serif;
      font-size: 21px;
      overflow-x: hidden;
      width: 665px;
      margin-top: 32px;
  }

  .logo .menu-header{
    background-color: #F9F9F9;
      padding: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .logo{
    padding: 0;
    width: 100%;
    height: 64px;
  }

  .logo img{
      width: 247px;
  }

  .logo .menu-hamburguer{
      display: block;
      width: 24px;
      height: 24px;
  }

  .logo .menu-burger{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 24px;
    height: 24px;
    z-index: 1000;
    position: relative;
  }

  .logo .menu-burger-content{
    height: 2px;
    width: 12px;
    border-radius: 2px;
    background-color: black;
    position: relative;
    display: block;
    z-index: 1000;
  }

  .logo .menu-burger-content::before, .logo .menu-burger-content::after{
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background-color: black;
    right: 0;
    content: '';
    position: absolute;
    transform: rotateZ(0deg);
    transition: all 0.3s var(--animation-curve);
  }

  .logo .menu-burger-content::before{
    top: -6px;
    transform-origin: left top;
  }

  .logo .menu-burger-content::after{
    bottom: -6px;
    transform-origin: left bottom;
  }

  .logo.active{
    background-color: #F15A29;
    height: 100vh;
  }

  .logo .active{
    background-color: transparent;
  }

  .logo .active::before{
    transform: rotateZ(44deg);
  }

  .logo .active::after{
    transform: rotate(-44deg);
  }

  .hero{
      padding: 104px 0 80px;
  }

  .hero .blur{
    right: -4%;
    top: -54px;
    width: 280px;
    height: 280px;
    background-image: url('/src/assets/blur-mobile.svg');
  }

  .hero h1{
      width: auto;
      font-size: 52px;
      margin-bottom: 48px;
      line-height: 56px;
      position: relative;
  }
  
  .hero span{
      font-size: 52px;
  }
  
  .hero h2{
      width: auto;
      font-size: 32px;
      line-height: 48px;
      margin-top: 96px;
  }

  .hero .workLottie{
    top: 48px;
    right: -18px;
  }

  .hero .arrowLottie{
    right: 20px;
    top: 306px;
  }

  .about {
      background-color: #1D1D1B;
      padding: 48px 0;
  }
  
  .about .container{
      flex-wrap: wrap;
      justify-content: space-between;
  }
  
  .about .about__description{
      width: auto;
  }
  
  .about .about__description h2{
      font-size: 32px;
      line-height: 48px;
  }
  
  .about .about__image img{
      width: 100%;
      margin-top: 40px;
  }

  .work {
    flex-direction: column;
    align-items: flex-start;
  }

  .work h4 {
    font-size: 32px;
    line-height: 48px;
    width: 100%;
  }

  .work span {
    font-size: 16px;
    line-height: 18px;
    padding: 15px 24px;
    margin-right: 16px;
  }

  .skills{
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .experience {
      padding: 80px 0;
  }
  
  .experience h2{
      max-width: auto;
      font-size: 32px;
      line-height: 48px;
  }

  .about-my-work {
    padding: 0 0 80px;
  }
  
  .about-my-work h2 {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 40px;
  }  
  
  .projects .slick-dots{
    bottom: -80px;
    width: 100%;
    left: 0;
    background: none;
    padding: 32px 64px;
  }

  .comming-soon .comming-soon-text{
    font-size: 40px;
  }

  .comming-soon .comming-soon-n{
    font-size: 40px;
  }
  
  .slick-dots li{
    width: auto;
    margin: 0;
  }
  
  .projects .slick-dots li button{
    width: 12px;
    height: 12px;
    background-color: #1D1D1B;
    opacity: 1;
    content: '';
    border-radius: 50%;
    margin: 0 12px;
  }

  .projects .slick-dots .slick-active button{
    background-color: #F15A29;
    width: 24px;
    height: 24px;
  }

  .projects .slick-dots li button::before{
    content: '';
  }

  .hobbies{
      padding: 86px 0 212px;
  }
  
  .hobbies h3{
      font-size: 36px;
      width: auto;
      line-height: 40px;
      margin: 0;
      position: relative;
  }
  
  .hobbies h3::before{
    position: absolute;
    bottom: -164px;
    right: 0;
    background-image: url('/src/assets/citrus-image.png');
    height: 320px;
    width: 240px;
    content: '';
    z-index: -1;
  }

  footer{
      padding: 48px 0;
  }
  
  footer h3, p{
      font-size: 32px;
      line-height: 48px;
  }
  
  footer h3{
      margin: 42px 0;
  }
  
  footer h3:first-of-type{
      margin: 0 0 42px;
  }
  
  footer h3:last-of-type{
      margin: 42px 0 0;
  }
  
  footer hr{
      margin: 24px 0;
  }

  footer a{
    overflow-wrap: break-word;
  }
}

@media screen and (max-width: 350px) {
  .hero h1, .hero span{
      font-size: 42px;
  }

  footer a{
      font-size: 28px;
  }
}

.post-hero {
  padding: 196px 0 120px;
}

.post-hero h1 {
  font-size: 64px;
  margin-bottom: 42px;
  color: #1D1D1B;
  white-space: pre-line;
}

.post-hero h2{
  font-size: 40px;
  line-height: 56px;
  color: rgba(29, 29, 27, 0.8);
  white-space: pre-line;
}

.topic {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 80px;
}

.topic h2 {
  font-size: 24px;
  font-weight: 400;
  color:#F15A29;
  line-height: 40px;
  width: 310px;
}

.topic p {
  font-size: 24px;
  color: #1D1D1B;
  line-height: 40px;
  max-width: 752px;
  white-space: pre-wrap;
}

.description-with-icon {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.description-with-icon img {
  margin: 6px 24px 0 0;
}

@media screen and (max-width: 768px) {
  .post-hero {
    padding: 102px 0 80px;
  }
  
  .post-hero h1 {
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 40px;
  }
  
  .post-hero h2{
    font-size: 32px;
    line-height: 48px;
  }

  .topic {
    flex-direction: column;
    margin-bottom: 40px;
  }
  
  .topic h2 {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .topic p {
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
  }

  .description-with-icon {
    margin-bottom: 24px;
  }
  
  .description-with-icon img {
    margin: 6px 9px 0 0;
  }
}

